/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Robot(props) {
  const { nodes, materials } = useGLTF("/models/Robot.gltf");
  return (
    <group {...props} dispose={null}>
      <group scale={10}>
        <mesh
          castShadow
          geometry={nodes["1600-0407-0003006"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.05, 0.2]}
          rotation={[2.48, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003007"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.05, 0.13]}
          rotation={[-1.92, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003014"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.02, 0.21]}
          rotation={[-1.92, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003015"].geometry}
          material={materials.ID27}
          position={[-0.21, 0, 0.13]}
          rotation={[1.85, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003016"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.02, 0.21]}
          rotation={[1.85, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003017"].geometry}
          material={materials.ID27}
          position={[-0.19, 0.02, 0.15]}
          rotation={[-0.03, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003021"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.06, 0.16]}
          rotation={[-0.03, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003027"].geometry}
          material={materials.ID27}
          position={[-0.21, 0, 0.2]}
          rotation={[-2.54, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003028"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.06, 0.16]}
          rotation={[-2.54, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003029"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.06, 0.18]}
          rotation={[3.11, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003035"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.06, 0.18]}
          rotation={[-0.66, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003038"].geometry}
          material={materials.ID27}
          position={[-0.19, 0, 0.2]}
          rotation={[1.23, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003039"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.05, 0.14]}
          rotation={[0.6, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003044"].geometry}
          material={materials.ID27}
          position={[-0.19, 0.02, 0.18]}
          rotation={[0.6, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003051"].geometry}
          material={materials.ID27}
          position={[-0.21, 0.02, 0.15]}
          rotation={[2.48, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003056"].geometry}
          material={materials.ID27}
          position={[-0.19, 0, 0.13]}
          rotation={[-0.66, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003057"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.03, 0.13]}
          rotation={[-1.29, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003060"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.05, 0.2]}
          rotation={[-1.29, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003062"].geometry}
          material={materials.ID27}
          position={[-0.21, 0.02, 0.18]}
          rotation={[3.11, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003064"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.03, 0.13]}
          rotation={[1.23, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003008"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.03, 0.13]}
          rotation={[1.23, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003010"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.06, 0.16]}
          rotation={[-2.54, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003018"].geometry}
          material={materials.ID35}
          position={[-0.19, 0.02, 0.18]}
          rotation={[0.6, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003022"].geometry}
          material={materials.ID35}
          position={[-0.21, 0.02, 0.18]}
          rotation={[3.11, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003024"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.05, 0.2]}
          rotation={[-1.29, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003030"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.06, 0.16]}
          rotation={[-0.03, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003031"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.06, 0.18]}
          rotation={[-0.66, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003034"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.05, 0.14]}
          rotation={[0.6, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003036"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.02, 0.21]}
          rotation={[1.85, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003043"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.02, 0.21]}
          rotation={[-1.92, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003046"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.06, 0.18]}
          rotation={[3.11, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003047"].geometry}
          material={materials.ID35}
          position={[-0.19, 0, 0.2]}
          rotation={[1.23, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003048"].geometry}
          material={materials.ID35}
          position={[-0.19, 0.02, 0.15]}
          rotation={[-0.03, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003053"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.05, 0.13]}
          rotation={[-1.92, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003054"].geometry}
          material={materials.ID35}
          position={[-0.21, 0.02, 0.15]}
          rotation={[2.48, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003058"].geometry}
          material={materials.ID35}
          position={[-0.19, 0, 0.13]}
          rotation={[-0.66, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003059"].geometry}
          material={materials.ID35}
          position={[-0.21, 0, 0.2]}
          rotation={[-2.54, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003061"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.05, 0.2]}
          rotation={[2.48, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003063"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.03, 0.13]}
          rotation={[-1.29, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003065"].geometry}
          material={materials.ID35}
          position={[-0.21, 0, 0.13]}
          rotation={[1.85, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003009"].geometry}
          material={materials.ID43}
          position={[-0.21, 0.02, 0.15]}
          rotation={[2.48, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003011"].geometry}
          material={materials.ID43}
          position={[-0.19, 0, 0.13]}
          rotation={[-0.66, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003012"].geometry}
          material={materials.ID43}
          position={[-0.19, 0.02, 0.15]}
          rotation={[-0.03, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003013"].geometry}
          material={materials.ID43}
          position={[-0.21, 0, 0.13]}
          rotation={[1.85, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003019"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.05, 0.14]}
          rotation={[0.6, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003020"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.03, 0.13]}
          rotation={[1.23, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003023"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.05, 0.2]}
          rotation={[2.48, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003025"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.05, 0.13]}
          rotation={[-1.92, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003026"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.05, 0.2]}
          rotation={[-1.29, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003032"].geometry}
          material={materials.ID43}
          position={[-0.19, 0, 0.2]}
          rotation={[1.23, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003033"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.02, 0.21]}
          rotation={[-1.92, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003037"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.06, 0.18]}
          rotation={[3.11, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003040"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.03, 0.13]}
          rotation={[-1.29, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003041"].geometry}
          material={materials.ID43}
          position={[-0.21, 0.02, 0.18]}
          rotation={[3.11, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003042"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.06, 0.16]}
          rotation={[-2.54, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003045"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.02, 0.21]}
          rotation={[1.85, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003049"].geometry}
          material={materials.ID43}
          position={[-0.19, 0.02, 0.18]}
          rotation={[0.6, -0.79, -1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003050"].geometry}
          material={materials.ID43}
          position={[-0.21, 0, 0.2]}
          rotation={[-2.54, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003052"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.06, 0.16]}
          rotation={[-0.03, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003055"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.06, 0.18]}
          rotation={[-0.66, 0.79, 1.6]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207002"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.04, 0.16]}
          rotation={[-1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207003"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.03, 0.19]}
          rotation={[2.8, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207004"].geometry}
          material={materials.ID59}
          position={[-0.21, 0, 0.18]}
          rotation={[1.22, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207005"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, 0.15]}
          rotation={[-0.35, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007002"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, 0.15]}
          rotation={[1.22, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007003"].geometry}
          material={materials.ID59}
          position={[-0.21, 0, 0.18]}
          rotation={[2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007004"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.04, 0.16]}
          rotation={[-0.35, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007005"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.03, 0.19]}
          rotation={[-1.92, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_right_slant_side_plate001"].geometry}
          material={materials.ID96}
          position={[-0.2, -0.02, 0.17]}
          rotation={[-1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_right_slant_side_plate002"].geometry}
          material={materials.ID96}
          position={[-0.21, -0.02, 0.17]}
          rotation={[1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_core002"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.02, 0.17]}
          rotation={[2.8, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle002"].geometry}
          material={materials.ID74}
          position={[-0.22, 0, 0.13]}
          rotation={[-0.66, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle003"].geometry}
          material={materials.ID74}
          position={[-0.22, 0.02, 0.16]}
          rotation={[-0.03, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle004"].geometry}
          material={materials.ID74}
          position={[-0.22, 0.02, 0.18]}
          rotation={[0.6, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle005"].geometry}
          material={materials.ID74}
          position={[-0.22, -0.06, 0.15]}
          rotation={[-2.55, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle006"].geometry}
          material={materials.ID74}
          position={[-0.22, -0.05, 0.13]}
          rotation={[-1.92, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle007"].geometry}
          material={materials.ID74}
          position={[-0.22, -0.03, 0.21]}
          rotation={[1.85, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle008"].geometry}
          material={materials.ID74}
          position={[-0.22, 0, 0.2]}
          rotation={[1.22, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle009"].geometry}
          material={materials.ID74}
          position={[-0.22, -0.02, 0.12]}
          rotation={[-1.29, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle010"].geometry}
          material={materials.ID74}
          position={[-0.22, -0.05, 0.2]}
          rotation={[2.48, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle011"].geometry}
          material={materials.ID74}
          position={[-0.22, -0.07, 0.18]}
          rotation={[3.11, 0.68, -2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core002"].geometry}
          material={materials.ID19}
          position={[-0.19, 0, 0.2]}
          rotation={[1.49, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core003"].geometry}
          material={materials.ID19}
          position={[-0.19, 0, 0.13]}
          rotation={[-0.4, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core004"].geometry}
          material={materials.ID19}
          position={[-0.19, -0.02, 0.21]}
          rotation={[2.12, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core005"].geometry}
          material={materials.ID19}
          position={[-0.19, -0.06, 0.18]}
          rotation={[-2.91, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core006"].geometry}
          material={materials.ID19}
          position={[-0.19, -0.03, 0.13]}
          rotation={[-1.03, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core007"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.02, 0.15]}
          rotation={[0.23, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core008"].geometry}
          material={materials.ID19}
          position={[-0.19, -0.07, 0.16]}
          rotation={[-2.28, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core009"].geometry}
          material={materials.ID19}
          position={[-0.19, -0.05, 0.2]}
          rotation={[2.74, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core010"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.02, 0.18]}
          rotation={[0.86, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core011"].geometry}
          material={materials.ID19}
          position={[-0.19, -0.05, 0.14]}
          rotation={[-1.65, 0.77, -1.76]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover002"].geometry}
          material={materials.ID19}
          position={[-0.18, -0.08, 0.24]}
          rotation={[1.14, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover003"].geometry}
          material={materials.ID19}
          position={[-0.18, -0.07, 0.09]}
          rotation={[3.02, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover004"].geometry}
          material={materials.ID19}
          position={[-0.18, -0.03, 0.26]}
          rotation={[0.51, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover005"].geometry}
          material={materials.ID19}
          position={[-0.18, -0.02, 0.08]}
          rotation={[-2.63, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover006"].geometry}
          material={materials.ID19}
          position={[-0.18, 0.03, 0.1]}
          rotation={[-2.01, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover007"].geometry}
          material={materials.ID19}
          position={[-0.18, -0.11, 0.19]}
          rotation={[1.76, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover008"].geometry}
          material={materials.ID19}
          position={[-0.18, -0.11, 0.14]}
          rotation={[2.39, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover009"].geometry}
          material={materials.ID19}
          position={[-0.18, 0.02, 0.24]}
          rotation={[-0.12, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover010"].geometry}
          material={materials.ID19}
          position={[-0.18, 0.06, 0.2]}
          rotation={[-0.75, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover011"].geometry}
          material={materials.ID19}
          position={[-0.18, 0.06, 0.15]}
          rotation={[-1.38, -0.39, -1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip002"].geometry}
          material={materials.ID11}
          position={[-0.18, 0.02, 0.15]}
          rotation={[0.06, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip003"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.05, 0.2]}
          rotation={[2.48, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip004"].geometry}
          material={materials.ID11}
          position={[-0.22, 0.02, 0.18]}
          rotation={[0.6, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip005"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.02, 0.21]}
          rotation={[1.95, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip006"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.06, 0.15]}
          rotation={[-2.55, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip007"].geometry}
          material={materials.ID11}
          position={[-0.22, 0, 0.2]}
          rotation={[1.22, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip008"].geometry}
          material={materials.ID11}
          position={[-0.18, 0, 0.2]}
          rotation={[1.32, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip009"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.07, 0.18]}
          rotation={[3.11, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip010"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.06, 0.19]}
          rotation={[-3.08, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip011"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.03, 0.21]}
          rotation={[1.85, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip012"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.07, 0.16]}
          rotation={[-2.45, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip013"].geometry}
          material={materials.ID11}
          position={[-0.22, 0.02, 0.16]}
          rotation={[-0.03, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip014"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.03, 0.13]}
          rotation={[-1.2, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip015"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.05, 0.13]}
          rotation={[-1.92, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip016"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.05, 0.21]}
          rotation={[2.57, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip017"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.05, 0.14]}
          rotation={[-1.82, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip018"].geometry}
          material={materials.ID11}
          position={[-0.18, 0.02, 0.18]}
          rotation={[0.69, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip019"].geometry}
          material={materials.ID11}
          position={[-0.18, 0, 0.13]}
          rotation={[-0.57, 0.78, 3.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip020"].geometry}
          material={materials.ID11}
          position={[-0.22, 0, 0.13]}
          rotation={[-0.66, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip021"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.02, 0.12]}
          rotation={[-1.29, 0.68, -0.43]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim002"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.06, 0.18]}
          rotation={[3.05, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim003"].geometry}
          material={materials.ID51}
          position={[-0.19, 0.02, 0.15]}
          rotation={[-0.1, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim004"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.02, 0.21]}
          rotation={[1.79, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim005"].geometry}
          material={materials.ID51}
          position={[-0.19, 0.02, 0.18]}
          rotation={[0.53, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim006"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.03, 0.13]}
          rotation={[-1.35, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim007"].geometry}
          material={materials.ID51}
          position={[-0.19, 0, 0.13]}
          rotation={[-0.72, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim008"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.05, 0.14]}
          rotation={[-1.98, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim009"].geometry}
          material={materials.ID51}
          position={[-0.19, 0, 0.2]}
          rotation={[1.16, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim010"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.07, 0.16]}
          rotation={[-2.61, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim011"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.05, 0.2]}
          rotation={[2.42, 0.78, -1.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1120-0010-0264002"].geometry}
          material={materials.ID119}
          position={[0.13, 0, 0]}
          rotation={[Math.PI, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002003"].geometry}
          material={materials.ID119}
          position={[0.13, -0.02, 0]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002004"].geometry}
          material={materials.ID119}
          position={[-0.13, -0.02, 0]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008009"].geometry}
          material={materials.ID59}
          position={[0.13, -0.01, 0.02]}
          rotation={[0, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008010"].geometry}
          material={materials.ID59}
          position={[0.13, -0.04, -0.02]}
          rotation={[Math.PI, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008011"].geometry}
          material={materials.ID59}
          position={[0.13, -0.04, 0.02]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008012"].geometry}
          material={materials.ID59}
          position={[0.13, -0.01, -0.02]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008013"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.04, -0.02]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008014"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.04, 0.02]}
          rotation={[-Math.PI, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008015"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.01, 0.02]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008016"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.01, -0.02]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011009"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.04, 0.01]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011010"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.01, -0.01]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011011"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.01, 0.01]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011012"].geometry}
          material={materials.ID59}
          position={[0.12, -0.01, -0.01]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011013"].geometry}
          material={materials.ID59}
          position={[0.12, -0.04, -0.01]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011014"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.04, -0.01]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011015"].geometry}
          material={materials.ID59}
          position={[0.12, -0.04, 0.01]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011016"].geometry}
          material={materials.ID59}
          position={[0.12, -0.01, 0.01]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2110-0608-0001008"].geometry}
          material={materials.ID201}
          position={[-0.2, -0.02, -0.17]}
          rotation={[-0.2, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0010008"].geometry}
          material={materials.ID59}
          position={[-0.14, -0.02, -0.17]}
          rotation={[-0.2, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0011008"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.02, -0.17]}
          rotation={[1.37, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id159009.geometry}
          material={materials.ID193}
          position={[-0.18, -0.02, -0.17]}
          rotation={[1.37, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id164009.geometry}
          material={materials.ID193}
          position={[-0.18, -0.02, -0.17]}
          rotation={[1.37, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id169009.geometry}
          material={materials.ID193}
          position={[-0.18, -0.02, -0.17]}
          rotation={[1.37, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id179009.geometry}
          material={materials.ID35}
          position={[-0.14, -0.02, -0.17]}
          rotation={[-1.77, 0, -Math.PI / 2]}
        />
        <group
          position={[-0.14, -0.02, -0.17]}
          rotation={[-1.77, 0, -Math.PI / 2]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID270.geometry}
            material={materials.ID149}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID270_1.geometry}
            material={materials.ID183}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID270_2.geometry}
            material={materials.ID184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID270_3.geometry}
            material={materials.ID185}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id189009.geometry}
          material={materials.ID27}
          position={[-0.14, -0.02, -0.17]}
          rotation={[-1.77, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030012"].geometry}
          material={materials.ID173}
          position={[-0.16, -0.02, 0.14]}
          rotation={[Math.PI / 2, 0.35, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004023"].geometry}
          material={materials.ID165}
          position={[-0.15, -0.02, 0.16]}
          rotation={[-Math.PI / 2, 1.22, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004024"].geometry}
          material={materials.ID165}
          position={[-0.16, -0.02, 0.16]}
          rotation={[-Math.PI / 2, -0.87, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030010"].geometry}
          material={materials.ID173}
          position={[-0.16, -0.02, -0.14]}
          rotation={[Math.PI / 2, -0.2, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004019"].geometry}
          material={materials.ID165}
          position={[-0.16, -0.02, -0.16]}
          rotation={[-Math.PI / 2, -1.37, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004020"].geometry}
          material={materials.ID165}
          position={[-0.15, -0.02, -0.16]}
          rotation={[-Math.PI / 2, 0.72, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030011"].geometry}
          material={materials.ID173}
          position={[-0.18, -0.02, -0.17]}
          rotation={[2.94, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004021"].geometry}
          material={materials.ID165}
          position={[-0.17, -0.02, -0.17]}
          rotation={[-1.77, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004022"].geometry}
          material={materials.ID165}
          position={[-0.17, -0.03, -0.16]}
          rotation={[0.32, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030009"].geometry}
          material={materials.ID173}
          position={[-0.18, -0.02, 0.17]}
          rotation={[-0.35, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004017"].geometry}
          material={materials.ID165}
          position={[-0.17, -0.03, 0.17]}
          rotation={[1.22, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004018"].geometry}
          material={materials.ID165}
          position={[-0.17, -0.02, 0.16]}
          rotation={[-2.96, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins020"].geometry}
          material={materials.ID149}
          position={[-0.15, -0.01, -0.04]}
          rotation={[-Math.PI / 2, Math.PI / 4, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9020.geometry}
          material={materials.ID149}
          position={[-0.15, -0.01, -0.04]}
          rotation={[-Math.PI / 2, Math.PI / 4, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins019"].geometry}
          material={materials.ID149}
          position={[-0.15, -0.02, -0.04]}
          rotation={[-Math.PI / 2, Math.PI / 4, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9019.geometry}
          material={materials.ID149}
          position={[-0.15, -0.02, -0.04]}
          rotation={[-Math.PI / 2, Math.PI / 4, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing027"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34027.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing028"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34028.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Barrel_(2_stage,_simple)010"].geometry}
          material={materials.ID165}
          position={[-0.16, -0.02, -0.14]}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)073"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.17, -0.02, -0.12]}
          rotation={[1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)074"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.14, -0.02, -0.12]}
          rotation={[-1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)075"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.04, -0.12]}
          rotation={[3.14, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)076"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.17, -0.02, -0.1]}
          rotation={[1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)077"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.01, -0.1]}
          rotation={[0, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)078"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.01, -0.12]}
          rotation={[Math.PI, -1.57, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)079"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.04, -0.1]}
          rotation={[0, 1.57, 3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)080"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.14, -0.02, -0.1]}
          rotation={[-1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Base010"].geometry}
          material={materials.ID396}
          position={[-0.16, -0.02, -0.1]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Face010"].geometry}
          material={materials.ID396}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Snap_Ring010"].geometry}
          material={materials.ID165}
          position={[-0.16, -0.02, -0.14]}
          rotation={[-Math.PI, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw011"].geometry}
          material={materials.ID59}
          position={[-0.16, -0.01, -0.03]}
          rotation={[-Math.PI / 2, -0.49, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw012"].geometry}
          material={materials.ID59}
          position={[-0.15, -0.04, -0.03]}
          rotation={[-Math.PI / 2, -0.49, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap006"].geometry}
          material={materials.ID19}
          position={[-0.16, -0.02, -0.02]}
          rotation={[-Math.PI / 2, Math.PI / 4, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pin_housing006"].geometry}
          material={materials.ID298}
          position={[-0.15, -0.01, -0.04]}
          rotation={[Math.PI, 0, -2.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_motor_shield006"].geometry}
          material={materials.ID359}
          position={[-0.16, -0.02, -0.09]}
          rotation={[-Math.PI / 2, 0.63, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0001_body006"].geometry}
          material={materials.ID74}
          position={[-0.16, -0.02, -0.04]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0117_motor_shaft006"].geometry}
          material={materials.ID351}
          position={[-0.16, -0.02, -0.1]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)021"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.15, -0.02, -0.1]}
          rotation={[Math.PI / 2, -0.06, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)022"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.16, -0.03, -0.1]}
          rotation={[-Math.PI / 2, 0.06, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)023"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.16, -0.02, -0.1]}
          rotation={[1.57, 1.51, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)024"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.15, -0.03, -0.1]}
          rotation={[-1.57, -1.51, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Shaft_(235mm_Length,_Simple)006"].geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.02, -0.12]}
          rotation={[0, 0, 1.37]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins018"].geometry}
          material={materials.ID149}
          position={[-0.15, -0.04, 0.04]}
          rotation={[Math.PI / 2, Math.PI / 4, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9018.geometry}
          material={materials.ID149}
          position={[-0.15, -0.04, 0.04]}
          rotation={[Math.PI / 2, Math.PI / 4, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins017"].geometry}
          material={materials.ID149}
          position={[-0.15, -0.03, 0.04]}
          rotation={[Math.PI / 2, Math.PI / 4, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9017.geometry}
          material={materials.ID149}
          position={[-0.15, -0.03, 0.04]}
          rotation={[Math.PI / 2, Math.PI / 4, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing026"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34026.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing025"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34025.geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Barrel_(2_stage,_simple)009"].geometry}
          material={materials.ID165}
          position={[-0.16, -0.02, 0.14]}
          rotation={[Math.PI / 2, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)065"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.14, -0.02, 0.12]}
          rotation={[1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)066"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.04, 0.12]}
          rotation={[0, -1.57, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)067"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.01, 0.12]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)068"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.04, 0.1]}
          rotation={[-3.14, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)069"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.17, -0.02, 0.1]}
          rotation={[-1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)070"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.14, -0.02, 0.1]}
          rotation={[1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)071"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.17, -0.02, 0.12]}
          rotation={[-1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)072"]
              .geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.01, 0.1]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Base009"].geometry}
          material={materials.ID396}
          position={[-0.16, -0.02, 0.1]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Face009"].geometry}
          material={materials.ID396}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Snap_Ring009"].geometry}
          material={materials.ID165}
          position={[-0.16, -0.02, 0.14]}
          rotation={[0, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw009"].geometry}
          material={materials.ID59}
          position={[-0.16, -0.04, 0.03]}
          rotation={[Math.PI / 2, -0.49, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw010"].geometry}
          material={materials.ID59}
          position={[-0.15, -0.01, 0.03]}
          rotation={[Math.PI / 2, -0.49, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap005"].geometry}
          material={materials.ID19}
          position={[-0.16, -0.02, 0.02]}
          rotation={[Math.PI / 2, Math.PI / 4, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pin_housing005"].geometry}
          material={materials.ID298}
          position={[-0.15, -0.03, 0.04]}
          rotation={[0, 0, -2.36]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_motor_shield005"].geometry}
          material={materials.ID359}
          position={[-0.16, -0.02, 0.09]}
          rotation={[Math.PI / 2, 0.63, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0001_body005"].geometry}
          material={materials.ID74}
          position={[-0.16, -0.02, 0.04]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0117_motor_shaft005"].geometry}
          material={materials.ID351}
          position={[-0.16, -0.02, 0.1]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)017"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.16, -0.02, 0.1]}
          rotation={[Math.PI / 2, 0.06, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)018"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.16, -0.03, 0.1]}
          rotation={[-1.57, 1.51, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)019"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.15, -0.02, 0.1]}
          rotation={[1.57, -1.51, 3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)020"]
              .geometry
          }
          material={materials.ID306}
          position={[-0.15, -0.03, 0.1]}
          rotation={[-Math.PI / 2, -0.06, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Shaft_(235mm_Length,_Simple)005"].geometry
          }
          material={materials.ID149}
          position={[-0.16, -0.02, 0.12]}
          rotation={[-Math.PI, 0, -1.92]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2110-0608-0001007"].geometry}
          material={materials.ID201}
          position={[-0.2, -0.02, 0.17]}
          rotation={[2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0010007"].geometry}
          material={materials.ID59}
          position={[-0.14, -0.02, 0.17]}
          rotation={[2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0011007"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.02, 0.17]}
          rotation={[-1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id159008.geometry}
          material={materials.ID193}
          position={[-0.18, -0.02, 0.17]}
          rotation={[-1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id164008.geometry}
          material={materials.ID193}
          position={[-0.18, -0.02, 0.17]}
          rotation={[-1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id169008.geometry}
          material={materials.ID193}
          position={[-0.18, -0.02, 0.17]}
          rotation={[-1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id179008.geometry}
          material={materials.ID237}
          position={[-0.14, -0.02, 0.17]}
          rotation={[1.22, 0, -Math.PI / 2]}
        />
        <group
          position={[-0.14, -0.02, 0.17]}
          rotation={[1.22, 0, -Math.PI / 2]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177.geometry}
            material={materials.ID149}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_1.geometry}
            material={materials.ID183}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_2.geometry}
            material={materials.ID184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_3.geometry}
            material={materials.ID185}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id189008.geometry}
          material={materials.ID11}
          position={[-0.14, -0.02, 0.17]}
          rotation={[1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002005"].geometry}
          material={materials.ID119}
          position={[-0.16, -0.02, -0.14]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002006"].geometry}
          material={materials.ID119}
          position={[-0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011017"].geometry}
          material={materials.ID59}
          position={[-0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011018"].geometry}
          material={materials.ID59}
          position={[-0.17, -0.04, -0.14]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011019"].geometry}
          material={materials.ID59}
          position={[-0.16, -0.03, -0.13]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011020"].geometry}
          material={materials.ID59}
          position={[-0.15, -0.03, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011021"].geometry}
          material={materials.ID59}
          position={[-0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011022"].geometry}
          material={materials.ID59}
          position={[-0.17, -0.01, 0.14]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011023"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.02, -0.18]}
          rotation={[-0.2, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011024"].geometry}
          material={materials.ID59}
          position={[-0.14, -0.01, 0.14]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011025"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.03, 0.18]}
          rotation={[2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011026"].geometry}
          material={materials.ID59}
          position={[-0.17, -0.04, 0.14]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011027"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.01, -0.16]}
          rotation={[-0.2, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011028"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.02, 0.16]}
          rotation={[2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011029"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.03, -0.17]}
          rotation={[-0.2, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011030"].geometry}
          material={materials.ID59}
          position={[-0.15, -0.02, 0.13]}
          rotation={[Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011031"].geometry}
          material={materials.ID59}
          position={[-0.17, -0.01, -0.14]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011032"].geometry}
          material={materials.ID59}
          position={[-0.16, -0.03, 0.13]}
          rotation={[Math.PI, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011033"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.01, 0.17]}
          rotation={[2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011034"].geometry}
          material={materials.ID59}
          position={[-0.15, -0.03, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011035"].geometry}
          material={materials.ID59}
          position={[-0.14, -0.01, -0.14]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011036"].geometry}
          material={materials.ID59}
          position={[-0.15, -0.02, -0.13]}
          rotation={[0, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011037"].geometry}
          material={materials.ID59}
          position={[-0.14, -0.04, -0.14]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011038"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.03, -0.16]}
          rotation={[-0.2, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011039"].geometry}
          material={materials.ID59}
          position={[-0.2, -0.03, 0.16]}
          rotation={[2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011040"].geometry}
          material={materials.ID59}
          position={[-0.14, -0.04, 0.14]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1120-0017-0432001"].geometry}
          material={materials.ID119}
          position={[-0.16, 0, -0.22]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1522-0010-0120001"].geometry}
          material={materials.ID119}
          position={[-0.16, -0.02, 0.15]}
          rotation={[0, 0, -1.22]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1522-0010-0120002"].geometry}
          material={materials.ID119}
          position={[-0.16, -0.02, -0.15]}
          rotation={[Math.PI, 0, 1.77]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008001"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.03, 0.16]}
          rotation={[2.8, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008002"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.03, -0.16]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008003"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.02, 0.16]}
          rotation={[2.8, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008004"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.03, 0.18]}
          rotation={[2.8, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008005"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, 0.17]}
          rotation={[2.8, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008006"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, -0.16]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008007"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.02, -0.18]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008008"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.03, -0.17]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500001"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, 1.22, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500002"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, 1.22, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500003"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.14]}
          rotation={[-1.57, -1.37, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500004"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.14]}
          rotation={[-1.57, -1.37, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500005"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, -0.14]}
          rotation={[-1.57, -1.37, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500006"].geometry}
          material={materials.ID149}
          position={[-0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, 1.22, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)257"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)233"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)253"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)269"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)254"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)232"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)240"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)249"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)258"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)248"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)271"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)275"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)234"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)260"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)264"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)243"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)225"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)277"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)237"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)230"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)247"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)274"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)229"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)265"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)267"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)227"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)273"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)245"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)236"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)261"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)266"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)239"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)223"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)272"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)235"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)256"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)259"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)224"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)270"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)244"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)241"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)238"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)252"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)246"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)255"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)276"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)278"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)262"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)231"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)226"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)228"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)250"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)242"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)251"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)263"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)268"].geometry}
          material={materials.ID19}
          position={[0.19, -0.11, 0.22]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1120-0010-0264003"].geometry}
          material={materials.ID119}
          position={[0.13, 0, -0.17]}
          rotation={[Math.PI, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002007"].geometry}
          material={materials.ID119}
          position={[0.13, -0.02, -0.17]}
          rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002008"].geometry}
          material={materials.ID119}
          position={[-0.13, -0.02, -0.17]}
          rotation={[-Math.PI / 2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008017"].geometry}
          material={materials.ID59}
          position={[0.13, -0.01, -0.18]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008018"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.01, -0.15]}
          rotation={[Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008019"].geometry}
          material={materials.ID59}
          position={[0.13, -0.04, -0.18]}
          rotation={[Math.PI, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008020"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.01, -0.18]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008021"].geometry}
          material={materials.ID59}
          position={[0.13, -0.04, -0.15]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008022"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.04, -0.18]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008023"].geometry}
          material={materials.ID59}
          position={[0.13, -0.01, -0.15]}
          rotation={[0, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0008024"].geometry}
          material={materials.ID59}
          position={[-0.13, -0.04, -0.15]}
          rotation={[-Math.PI, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011041"].geometry}
          material={materials.ID59}
          position={[0.12, -0.01, -0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011042"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.04, -0.16]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011043"].geometry}
          material={materials.ID59}
          position={[0.12, -0.04, -0.16]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011044"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.01, -0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011045"].geometry}
          material={materials.ID59}
          position={[0.12, -0.01, -0.16]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011046"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.04, -0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011047"].geometry}
          material={materials.ID59}
          position={[-0.12, -0.01, -0.16]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011048"].geometry}
          material={materials.ID59}
          position={[0.12, -0.04, -0.18]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)168"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)169"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)170"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)171"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)172"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)173"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)174"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)175"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)176"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)177"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)178"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)179"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)180"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)181"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)182"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)183"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)184"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)185"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)186"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)187"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)188"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)189"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)190"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)191"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)192"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)193"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)194"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)195"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)196"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)197"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)198"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)199"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)200"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)201"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)202"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)203"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)204"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)205"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)206"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)207"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)208"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)209"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)210"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)211"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)212"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)213"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)214"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)215"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)216"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)217"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)218"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)219"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)220"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)221"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)222"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)279"].geometry}
          material={materials.ID19}
          position={[0.21, -0.08, 0.09]}
          rotation={[-2.95, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003066"].geometry}
          material={materials.ID27}
          position={[0.21, -0.02, 0.13]}
          rotation={[1.46, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003074"].geometry}
          material={materials.ID27}
          position={[0.21, -0.03, 0.21]}
          rotation={[-1.69, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003076"].geometry}
          material={materials.ID27}
          position={[0.19, -0.06, 0.2]}
          rotation={[2.71, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003077"].geometry}
          material={materials.ID27}
          position={[0.21, -0.07, 0.17]}
          rotation={[-0.43, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003078"].geometry}
          material={materials.ID27}
          position={[0.21, -0.06, 0.15]}
          rotation={[0.2, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003080"].geometry}
          material={materials.ID27}
          position={[0.21, 0.02, 0.16]}
          rotation={[2.71, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003085"].geometry}
          material={materials.ID27}
          position={[0.21, -0.05, 0.2]}
          rotation={[-1.06, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003087"].geometry}
          material={materials.ID27}
          position={[0.19, 0.01, 0.19]}
          rotation={[0.83, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003096"].geometry}
          material={materials.ID27}
          position={[0.19, 0.02, 0.16]}
          rotation={[0.2, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003097"].geometry}
          material={materials.ID27}
          position={[0.19, -0.03, 0.21]}
          rotation={[2.08, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003098"].geometry}
          material={materials.ID27}
          position={[0.21, 0.01, 0.19]}
          rotation={[-2.94, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003103"].geometry}
          material={materials.ID27}
          position={[0.19, -0.01, 0.21]}
          rotation={[1.46, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003110"].geometry}
          material={materials.ID27}
          position={[0.21, 0.01, 0.14]}
          rotation={[2.08, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003111"].geometry}
          material={materials.ID27}
          position={[0.19, -0.07, 0.17]}
          rotation={[-2.94, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003112"].geometry}
          material={materials.ID27}
          position={[0.21, -0.04, 0.13]}
          rotation={[0.83, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003113"].geometry}
          material={materials.ID27}
          position={[0.19, -0.04, 0.13]}
          rotation={[-1.69, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003116"].geometry}
          material={materials.ID27}
          position={[0.21, -0.01, 0.21]}
          rotation={[-2.31, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003118"].geometry}
          material={materials.ID27}
          position={[0.19, 0.01, 0.14]}
          rotation={[-0.43, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003119"].geometry}
          material={materials.ID27}
          position={[0.19, -0.02, 0.13]}
          rotation={[-1.06, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003121"].geometry}
          material={materials.ID27}
          position={[0.19, -0.06, 0.15]}
          rotation={[-2.31, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003067"].geometry}
          material={materials.ID35}
          position={[0.19, -0.07, 0.17]}
          rotation={[-2.94, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003068"].geometry}
          material={materials.ID35}
          position={[0.19, -0.04, 0.13]}
          rotation={[-1.69, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003071"].geometry}
          material={materials.ID35}
          position={[0.21, -0.02, 0.13]}
          rotation={[1.46, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003072"].geometry}
          material={materials.ID35}
          position={[0.19, -0.06, 0.2]}
          rotation={[2.71, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003073"].geometry}
          material={materials.ID35}
          position={[0.21, 0.01, 0.19]}
          rotation={[-2.94, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003081"].geometry}
          material={materials.ID35}
          position={[0.19, -0.03, 0.21]}
          rotation={[2.08, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003082"].geometry}
          material={materials.ID35}
          position={[0.19, 0.01, 0.14]}
          rotation={[-0.43, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003083"].geometry}
          material={materials.ID35}
          position={[0.19, -0.02, 0.13]}
          rotation={[-1.06, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003092"].geometry}
          material={materials.ID35}
          position={[0.19, 0.01, 0.19]}
          rotation={[0.83, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003093"].geometry}
          material={materials.ID35}
          position={[0.21, -0.04, 0.13]}
          rotation={[0.83, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003095"].geometry}
          material={materials.ID35}
          position={[0.19, -0.06, 0.15]}
          rotation={[-2.31, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003099"].geometry}
          material={materials.ID35}
          position={[0.19, 0.02, 0.16]}
          rotation={[0.2, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003100"].geometry}
          material={materials.ID35}
          position={[0.19, -0.01, 0.21]}
          rotation={[1.46, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003101"].geometry}
          material={materials.ID35}
          position={[0.21, -0.03, 0.21]}
          rotation={[-1.69, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003104"].geometry}
          material={materials.ID35}
          position={[0.21, -0.01, 0.21]}
          rotation={[-2.31, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003106"].geometry}
          material={materials.ID35}
          position={[0.21, -0.05, 0.2]}
          rotation={[-1.06, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003115"].geometry}
          material={materials.ID35}
          position={[0.21, 0.02, 0.16]}
          rotation={[2.71, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003123"].geometry}
          material={materials.ID35}
          position={[0.21, -0.07, 0.17]}
          rotation={[-0.43, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003124"].geometry}
          material={materials.ID35}
          position={[0.21, -0.06, 0.15]}
          rotation={[0.2, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003125"].geometry}
          material={materials.ID35}
          position={[0.21, 0.01, 0.14]}
          rotation={[2.08, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003069"].geometry}
          material={materials.ID43}
          position={[0.19, -0.01, 0.21]}
          rotation={[1.46, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003070"].geometry}
          material={materials.ID43}
          position={[0.21, -0.06, 0.15]}
          rotation={[0.2, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003075"].geometry}
          material={materials.ID43}
          position={[0.19, -0.06, 0.2]}
          rotation={[2.71, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003079"].geometry}
          material={materials.ID43}
          position={[0.19, 0.01, 0.19]}
          rotation={[0.83, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003084"].geometry}
          material={materials.ID43}
          position={[0.21, -0.03, 0.21]}
          rotation={[-1.69, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003086"].geometry}
          material={materials.ID43}
          position={[0.19, -0.03, 0.21]}
          rotation={[2.08, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003088"].geometry}
          material={materials.ID43}
          position={[0.21, -0.02, 0.13]}
          rotation={[1.46, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003089"].geometry}
          material={materials.ID43}
          position={[0.21, 0.02, 0.16]}
          rotation={[2.71, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003090"].geometry}
          material={materials.ID43}
          position={[0.21, 0.01, 0.14]}
          rotation={[2.08, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003091"].geometry}
          material={materials.ID43}
          position={[0.21, -0.04, 0.13]}
          rotation={[0.83, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003094"].geometry}
          material={materials.ID43}
          position={[0.19, -0.07, 0.17]}
          rotation={[-2.94, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003102"].geometry}
          material={materials.ID43}
          position={[0.21, -0.07, 0.17]}
          rotation={[-0.43, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003105"].geometry}
          material={materials.ID43}
          position={[0.21, 0.01, 0.19]}
          rotation={[-2.94, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003107"].geometry}
          material={materials.ID43}
          position={[0.19, -0.06, 0.15]}
          rotation={[-2.31, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003108"].geometry}
          material={materials.ID43}
          position={[0.19, 0.01, 0.14]}
          rotation={[-0.43, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003109"].geometry}
          material={materials.ID43}
          position={[0.19, -0.02, 0.13]}
          rotation={[-1.06, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003114"].geometry}
          material={materials.ID43}
          position={[0.21, -0.01, 0.21]}
          rotation={[-2.31, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003117"].geometry}
          material={materials.ID43}
          position={[0.19, 0.02, 0.16]}
          rotation={[0.2, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003120"].geometry}
          material={materials.ID43}
          position={[0.19, -0.04, 0.13]}
          rotation={[-1.69, 0.79, -0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003122"].geometry}
          material={materials.ID43}
          position={[0.21, -0.05, 0.2]}
          rotation={[-1.06, -0.79, 0.98]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207006"].geometry}
          material={materials.ID59}
          position={[0.21, -0.04, 0.15]}
          rotation={[0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207007"].geometry}
          material={materials.ID59}
          position={[0.21, -0.04, 0.18]}
          rotation={[-1.37, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207008"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, 0.16]}
          rotation={[1.77, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207009"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, 0.19]}
          rotation={[-2.94, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007006"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, 0.16]}
          rotation={[-2.94, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007007"].geometry}
          material={materials.ID59}
          position={[0.21, -0.04, 0.18]}
          rotation={[0.2, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007008"].geometry}
          material={materials.ID59}
          position={[0.21, -0.04, 0.15]}
          rotation={[1.77, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007009"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, 0.19]}
          rotation={[-1.37, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_left_slant_side_plate001"].geometry}
          material={materials.ID96}
          position={[0.19, -0.02, 0.17]}
          rotation={[-2.94, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_left_slant_side_plate002"].geometry}
          material={materials.ID96}
          position={[0.21, -0.02, 0.17]}
          rotation={[-2.94, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_core003"].geometry}
          material={materials.ID19}
          position={[0.2, -0.02, 0.17]}
          rotation={[-2.94, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle012"].geometry}
          material={materials.ID74}
          position={[0.18, -0.07, 0.18]}
          rotation={[-1.77, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle013"].geometry}
          material={materials.ID74}
          position={[0.18, -0.04, 0.13]}
          rotation={[-0.51, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle014"].geometry}
          material={materials.ID74}
          position={[0.18, 0.01, 0.14]}
          rotation={[0.75, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle015"].geometry}
          material={materials.ID74}
          position={[0.18, -0.03, 0.21]}
          rotation={[-3.02, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle016"].geometry}
          material={materials.ID74}
          position={[0.18, -0.02, 0.13]}
          rotation={[0.12, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle017"].geometry}
          material={materials.ID74}
          position={[0.18, -0.06, 0.15]}
          rotation={[-1.14, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle018"].geometry}
          material={materials.ID74}
          position={[0.18, 0.01, 0.19]}
          rotation={[2, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle019"].geometry}
          material={materials.ID74}
          position={[0.18, -0.05, 0.2]}
          rotation={[-2.4, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle020"].geometry}
          material={materials.ID74}
          position={[0.18, 0, 0.21]}
          rotation={[2.63, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle021"].geometry}
          material={materials.ID74}
          position={[0.18, 0.02, 0.16]}
          rotation={[1.37, -0.37, -0.86]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core012"].geometry}
          material={materials.ID19}
          position={[0.22, -0.04, 0.13]}
          rotation={[-1.39, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core013"].geometry}
          material={materials.ID19}
          position={[0.22, 0.01, 0.19]}
          rotation={[1.12, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core014"].geometry}
          material={materials.ID19}
          position={[0.22, -0.07, 0.17]}
          rotation={[-2.65, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core015"].geometry}
          material={materials.ID19}
          position={[0.22, -0.01, 0.21]}
          rotation={[1.75, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core016"].geometry}
          material={materials.ID19}
          position={[0.22, -0.06, 0.2]}
          rotation={[3.01, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core017"].geometry}
          material={materials.ID19}
          position={[0.22, 0.01, 0.14]}
          rotation={[-0.14, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core018"].geometry}
          material={materials.ID19}
          position={[0.22, 0.02, 0.16]}
          rotation={[0.49, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core019"].geometry}
          material={materials.ID19}
          position={[0.22, -0.01, 0.13]}
          rotation={[-0.76, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core020"].geometry}
          material={materials.ID19}
          position={[0.22, -0.03, 0.21]}
          rotation={[2.38, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core021"].geometry}
          material={materials.ID19}
          position={[0.22, -0.06, 0.15]}
          rotation={[-2.02, -0.54, -0.97]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover012"].geometry}
          material={materials.ID19}
          position={[0.2, -0.05, 0.08]}
          rotation={[2.36, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover013"].geometry}
          material={materials.ID19}
          position={[0.2, 0, 0.08]}
          rotation={[2.99, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover014"].geometry}
          material={materials.ID19}
          position={[0.2, -0.1, 0.12]}
          rotation={[1.74, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover015"].geometry}
          material={materials.ID19}
          position={[0.2, 0.01, 0.25]}
          rotation={[-0.78, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover016"].geometry}
          material={materials.ID19}
          position={[0.2, 0.05, 0.22]}
          rotation={[-1.41, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover017"].geometry}
          material={materials.ID19}
          position={[0.2, 0.05, 0.11]}
          rotation={[-2.66, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover018"].geometry}
          material={materials.ID19}
          position={[0.2, -0.11, 0.17]}
          rotation={[1.11, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover019"].geometry}
          material={materials.ID19}
          position={[0.2, -0.05, 0.25]}
          rotation={[-0.15, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover020"].geometry}
          material={materials.ID19}
          position={[0.2, -0.09, 0.22]}
          rotation={[0.48, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover021"].geometry}
          material={materials.ID19}
          position={[0.2, 0.06, 0.17]}
          rotation={[-2.03, -0.59, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip022"].geometry}
          material={materials.ID11}
          position={[0.18, -0.07, 0.18]}
          rotation={[-2.14, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip023"].geometry}
          material={materials.ID11}
          position={[0.22, -0.06, 0.14]}
          rotation={[-2.14, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip024"].geometry}
          material={materials.ID11}
          position={[0.22, 0.02, 0.17]}
          rotation={[0.37, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip025"].geometry}
          material={materials.ID11}
          position={[0.18, -0.02, 0.13]}
          rotation={[-0.26, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip026"].geometry}
          material={materials.ID11}
          position={[0.18, 0.02, 0.16]}
          rotation={[1, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip027"].geometry}
          material={materials.ID11}
          position={[0.18, -0.04, 0.13]}
          rotation={[-0.89, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip028"].geometry}
          material={materials.ID11}
          position={[0.18, -0.03, 0.21]}
          rotation={[2.88, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip029"].geometry}
          material={materials.ID11}
          position={[0.22, -0.01, 0.21]}
          rotation={[1.63, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip030"].geometry}
          material={materials.ID11}
          position={[0.22, -0.06, 0.19]}
          rotation={[2.88, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip031"].geometry}
          material={materials.ID11}
          position={[0.22, 0.01, 0.19]}
          rotation={[1, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip032"].geometry}
          material={materials.ID11}
          position={[0.18, 0.02, 0.19]}
          rotation={[1.63, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip033"].geometry}
          material={materials.ID11}
          position={[0.22, -0.04, 0.13]}
          rotation={[-1.52, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip034"].geometry}
          material={materials.ID11}
          position={[0.18, -0.06, 0.15]}
          rotation={[-1.52, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip035"].geometry}
          material={materials.ID11}
          position={[0.22, -0.01, 0.13]}
          rotation={[-0.89, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip036"].geometry}
          material={materials.ID11}
          position={[0.22, -0.07, 0.17]}
          rotation={[-2.77, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip037"].geometry}
          material={materials.ID11}
          position={[0.18, -0.05, 0.2]}
          rotation={[-2.77, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip038"].geometry}
          material={materials.ID11}
          position={[0.18, 0.01, 0.14]}
          rotation={[0.37, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip039"].geometry}
          material={materials.ID11}
          position={[0.22, -0.04, 0.21]}
          rotation={[2.25, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip040"].geometry}
          material={materials.ID11}
          position={[0.22, 0.01, 0.14]}
          rotation={[-0.26, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip041"].geometry}
          material={materials.ID11}
          position={[0.18, 0, 0.21]}
          rotation={[2.25, -0.61, -2.61]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim012"].geometry}
          material={materials.ID51}
          position={[0.19, -0.04, 0.13]}
          rotation={[-1.01, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim013"].geometry}
          material={materials.ID51}
          position={[0.22, 0.01, 0.19]}
          rotation={[0.88, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim014"].geometry}
          material={materials.ID51}
          position={[0.19, -0.07, 0.17]}
          rotation={[-2.27, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim015"].geometry}
          material={materials.ID51}
          position={[0.22, 0.01, 0.14]}
          rotation={[-0.38, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim016"].geometry}
          material={materials.ID51}
          position={[0.22, -0.06, 0.2]}
          rotation={[2.76, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim017"].geometry}
          material={materials.ID51}
          position={[0.22, -0.04, 0.13]}
          rotation={[-1.64, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim018"].geometry}
          material={materials.ID51}
          position={[0.19, 0.02, 0.16]}
          rotation={[0.88, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim019"].geometry}
          material={materials.ID51}
          position={[0.22, 0.02, 0.16]}
          rotation={[0.25, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim020"].geometry}
          material={materials.ID51}
          position={[0.19, -0.03, 0.21]}
          rotation={[2.76, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim021"].geometry}
          material={materials.ID51}
          position={[0.22, -0.03, 0.21]}
          rotation={[2.13, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim022"].geometry}
          material={materials.ID51}
          position={[0.19, -0.06, 0.15]}
          rotation={[-1.64, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim023"].geometry}
          material={materials.ID51}
          position={[0.19, 0, 0.21]}
          rotation={[2.13, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim024"].geometry}
          material={materials.ID51}
          position={[0.22, -0.01, 0.13]}
          rotation={[-1.01, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim025"].geometry}
          material={materials.ID51}
          position={[0.19, 0.01, 0.19]}
          rotation={[1.5, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim026"].geometry}
          material={materials.ID51}
          position={[0.19, -0.05, 0.2]}
          rotation={[-2.89, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim027"].geometry}
          material={materials.ID51}
          position={[0.22, -0.07, 0.17]}
          rotation={[-2.89, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim028"].geometry}
          material={materials.ID51}
          position={[0.22, -0.01, 0.21]}
          rotation={[1.5, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim029"].geometry}
          material={materials.ID51}
          position={[0.19, 0.01, 0.14]}
          rotation={[0.25, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim030"].geometry}
          material={materials.ID51}
          position={[0.19, -0.02, 0.13]}
          rotation={[-0.38, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim031"].geometry}
          material={materials.ID51}
          position={[0.22, -0.06, 0.15]}
          rotation={[-2.27, -0.66, -1.11]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)353"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)347"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)360"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)354"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)349"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)343"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)361"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)358"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)386"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)363"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)336"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)337"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)335"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)387"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)389"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)375"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)379"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)382"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)364"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)366"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)377"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)388"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)351"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)370"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)385"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)356"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)381"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)340"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)344"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)371"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)369"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)342"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)348"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)380"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)345"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)384"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)378"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)352"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)355"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)359"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)338"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)376"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)362"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)374"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)372"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)350"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)346"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)390"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)383"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)341"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)367"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)339"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)373"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)368"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)357"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)365"].geometry}
          material={materials.ID19}
          position={[-0.19, 0.06, -0.12]}
          rotation={[-0.2, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)295"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)291"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)292"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)334"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)317"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)322"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)286"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)308"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)297"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)284"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)326"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)296"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)313"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)288"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)282"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)302"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)328"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)316"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)285"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)327"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)300"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)290"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)280"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)325"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)304"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)315"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)301"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)293"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)331"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)289"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)311"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)298"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)329"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)309"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)391"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)333"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)330"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)294"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)287"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)281"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)320"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)310"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)299"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)306"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)319"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)321"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)332"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)283"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)323"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)318"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)307"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)305"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)324"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)303"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)312"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["(Unsaved)314"].geometry}
          material={materials.ID19}
          position={[-0.21, 0.04, -0.25]}
          rotation={[2.95, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003126"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.03, -0.21]}
          rotation={[1.06, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003127"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.06, -0.2]}
          rotation={[0.43, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003129"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.02, -0.13]}
          rotation={[1.69, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003130"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.01, -0.21]}
          rotation={[1.69, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003132"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.04, -0.13]}
          rotation={[2.31, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003135"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.02, -0.13]}
          rotation={[-2.08, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003138"].geometry}
          material={materials.ID27}
          position={[-0.21, 0.02, -0.16]}
          rotation={[0.43, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003140"].geometry}
          material={materials.ID27}
          position={[-0.19, 0.02, -0.16]}
          rotation={[2.94, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003144"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.01, -0.21]}
          rotation={[-0.83, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003148"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.03, -0.21]}
          rotation={[-1.46, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003156"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.04, -0.13]}
          rotation={[-1.46, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003160"].geometry}
          material={materials.ID27}
          position={[-0.21, 0.01, -0.14]}
          rotation={[1.06, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003163"].geometry}
          material={materials.ID27}
          position={[-0.21, 0.01, -0.19]}
          rotation={[-0.2, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003165"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.07, -0.17]}
          rotation={[-2.71, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003168"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.07, -0.17]}
          rotation={[-0.2, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003172"].geometry}
          material={materials.ID27}
          position={[-0.19, 0.01, -0.19]}
          rotation={[2.31, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003174"].geometry}
          material={materials.ID27}
          position={[-0.19, -0.06, -0.15]}
          rotation={[-0.83, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003178"].geometry}
          material={materials.ID27}
          position={[-0.19, 0.01, -0.14]}
          rotation={[-2.71, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003179"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.05, -0.2]}
          rotation={[-2.08, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003181"].geometry}
          material={materials.ID27}
          position={[-0.21, -0.06, -0.15]}
          rotation={[2.94, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003128"].geometry}
          material={materials.ID35}
          position={[-0.21, 0.01, -0.19]}
          rotation={[-0.2, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003133"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.02, -0.13]}
          rotation={[1.69, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003134"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.06, -0.15]}
          rotation={[-0.83, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003136"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.04, -0.13]}
          rotation={[2.31, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003143"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.05, -0.2]}
          rotation={[-2.08, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003147"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.02, -0.13]}
          rotation={[-2.08, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003151"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.03, -0.21]}
          rotation={[-1.46, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003152"].geometry}
          material={materials.ID35}
          position={[-0.19, 0.01, -0.14]}
          rotation={[-2.71, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003153"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.07, -0.17]}
          rotation={[-0.2, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003155"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.07, -0.17]}
          rotation={[-2.71, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003159"].geometry}
          material={materials.ID35}
          position={[-0.19, 0.02, -0.16]}
          rotation={[2.94, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003161"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.04, -0.13]}
          rotation={[-1.46, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003162"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.06, -0.15]}
          rotation={[2.94, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003166"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.03, -0.21]}
          rotation={[1.06, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003167"].geometry}
          material={materials.ID35}
          position={[-0.21, 0.02, -0.16]}
          rotation={[0.43, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003169"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.06, -0.2]}
          rotation={[0.43, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003175"].geometry}
          material={materials.ID35}
          position={[-0.21, -0.01, -0.21]}
          rotation={[-0.83, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003177"].geometry}
          material={materials.ID35}
          position={[-0.19, -0.01, -0.21]}
          rotation={[1.69, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003180"].geometry}
          material={materials.ID35}
          position={[-0.21, 0.01, -0.14]}
          rotation={[1.06, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003183"].geometry}
          material={materials.ID35}
          position={[-0.19, 0.01, -0.19]}
          rotation={[2.31, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003131"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.07, -0.17]}
          rotation={[-0.2, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003137"].geometry}
          material={materials.ID43}
          position={[-0.21, 0.01, -0.19]}
          rotation={[-0.2, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003139"].geometry}
          material={materials.ID43}
          position={[-0.21, 0.02, -0.16]}
          rotation={[0.43, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003141"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.01, -0.21]}
          rotation={[1.69, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003142"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.06, -0.15]}
          rotation={[-0.83, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003145"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.02, -0.13]}
          rotation={[1.69, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003146"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.04, -0.13]}
          rotation={[2.31, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003149"].geometry}
          material={materials.ID43}
          position={[-0.21, 0.01, -0.14]}
          rotation={[1.06, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003150"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.05, -0.2]}
          rotation={[-2.08, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003154"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.06, -0.15]}
          rotation={[2.94, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003157"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.03, -0.21]}
          rotation={[-1.46, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003158"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.01, -0.21]}
          rotation={[-0.83, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003164"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.06, -0.2]}
          rotation={[0.43, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003170"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.02, -0.13]}
          rotation={[-2.08, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003171"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.04, -0.13]}
          rotation={[-1.46, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003173"].geometry}
          material={materials.ID43}
          position={[-0.19, 0.01, -0.19]}
          rotation={[2.31, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003176"].geometry}
          material={materials.ID43}
          position={[-0.21, -0.07, -0.17]}
          rotation={[-2.71, 0.79, -2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003182"].geometry}
          material={materials.ID43}
          position={[-0.19, -0.03, -0.21]}
          rotation={[1.06, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003184"].geometry}
          material={materials.ID43}
          position={[-0.19, 0.02, -0.16]}
          rotation={[2.94, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003185"].geometry}
          material={materials.ID43}
          position={[-0.19, 0.01, -0.14]}
          rotation={[-2.71, -0.79, 2.16]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207010"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.04, -0.18]}
          rotation={[-1.77, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207011"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.04, -0.15]}
          rotation={[2.94, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207012"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, -0.19]}
          rotation={[-0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207013"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, -0.16]}
          rotation={[1.37, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007010"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.04, -0.18]}
          rotation={[-0.2, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007011"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.04, -0.15]}
          rotation={[-1.77, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007012"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, -0.19]}
          rotation={[1.37, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007013"].geometry}
          material={materials.ID59}
          position={[-0.21, -0.01, -0.16]}
          rotation={[2.94, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_left_slant_side_plate003"].geometry}
          material={materials.ID96}
          position={[-0.21, -0.02, -0.17]}
          rotation={[2.94, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_left_slant_side_plate004"].geometry}
          material={materials.ID96}
          position={[-0.19, -0.02, -0.17]}
          rotation={[2.94, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_core004"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.02, -0.17]}
          rotation={[2.94, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle022"].geometry}
          material={materials.ID74}
          position={[-0.18, -0.04, -0.13]}
          rotation={[-2.63, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle023"].geometry}
          material={materials.ID74}
          position={[-0.18, 0.01, -0.14]}
          rotation={[2.4, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle024"].geometry}
          material={materials.ID74}
          position={[-0.18, 0, -0.21]}
          rotation={[0.51, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle025"].geometry}
          material={materials.ID74}
          position={[-0.18, -0.03, -0.21]}
          rotation={[-0.12, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle026"].geometry}
          material={materials.ID74}
          position={[-0.18, 0.01, -0.19]}
          rotation={[1.14, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle027"].geometry}
          material={materials.ID74}
          position={[-0.18, -0.05, -0.2]}
          rotation={[-0.75, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle028"].geometry}
          material={materials.ID74}
          position={[-0.18, -0.06, -0.15]}
          rotation={[-2, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle029"].geometry}
          material={materials.ID74}
          position={[-0.18, -0.02, -0.13]}
          rotation={[3.02, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle030"].geometry}
          material={materials.ID74}
          position={[-0.18, 0.02, -0.16]}
          rotation={[1.77, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle031"].geometry}
          material={materials.ID74}
          position={[-0.18, -0.07, -0.18]}
          rotation={[-1.37, 0.37, 2.28]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core022"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.01, -0.21]}
          rotation={[1.39, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core023"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.01, -0.13]}
          rotation={[-2.38, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core024"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.06, -0.15]}
          rotation={[-1.12, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core025"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.07, -0.17]}
          rotation={[-0.49, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core026"].geometry}
          material={materials.ID19}
          position={[-0.22, 0.01, -0.14]}
          rotation={[-3.01, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core027"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.06, -0.2]}
          rotation={[0.14, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core028"].geometry}
          material={materials.ID19}
          position={[-0.22, 0.02, -0.16]}
          rotation={[2.65, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core029"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.04, -0.13]}
          rotation={[-1.75, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core030"].geometry}
          material={materials.ID19}
          position={[-0.22, 0.01, -0.19]}
          rotation={[2.02, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core031"].geometry}
          material={materials.ID19}
          position={[-0.22, -0.03, -0.21]}
          rotation={[0.76, 0.54, 2.17]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover022"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.05, -0.08]}
          rotation={[0.78, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover023"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.1, -0.12]}
          rotation={[1.41, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover024"].geometry}
          material={materials.ID19}
          position={[-0.2, 0.05, -0.22]}
          rotation={[-1.74, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover025"].geometry}
          material={materials.ID19}
          position={[-0.2, 0.05, -0.11]}
          rotation={[-0.48, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover026"].geometry}
          material={materials.ID19}
          position={[-0.2, 0.06, -0.17]}
          rotation={[-1.11, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover027"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.11, -0.17]}
          rotation={[2.03, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover028"].geometry}
          material={materials.ID19}
          position={[-0.2, 0, -0.08]}
          rotation={[0.15, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover029"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.05, -0.25]}
          rotation={[-2.99, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover030"].geometry}
          material={materials.ID19}
          position={[-0.2, 0.01, -0.25]}
          rotation={[-2.36, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover031"].geometry}
          material={materials.ID19}
          position={[-0.2, -0.09, -0.22]}
          rotation={[2.66, 0.59, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip042"].geometry}
          material={materials.ID11}
          position={[-0.18, 0, -0.21]}
          rotation={[0.89, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip043"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.03, -0.21]}
          rotation={[0.26, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip044"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.01, -0.13]}
          rotation={[-2.25, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip045"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.04, -0.13]}
          rotation={[-2.25, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip046"].geometry}
          material={materials.ID11}
          position={[-0.18, 0.01, -0.14]}
          rotation={[2.77, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip047"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.01, -0.21]}
          rotation={[1.52, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip048"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.07, -0.17]}
          rotation={[-0.37, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip049"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.06, -0.14]}
          rotation={[-1, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip050"].geometry}
          material={materials.ID11}
          position={[-0.22, 0.02, -0.17]}
          rotation={[2.77, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip051"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.07, -0.18]}
          rotation={[-1, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip052"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.04, -0.13]}
          rotation={[-1.63, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip053"].geometry}
          material={materials.ID11}
          position={[-0.18, 0.02, -0.16]}
          rotation={[2.14, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip054"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.06, -0.15]}
          rotation={[-1.63, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip055"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.05, -0.2]}
          rotation={[-0.37, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip056"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.06, -0.19]}
          rotation={[0.26, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip057"].geometry}
          material={materials.ID11}
          position={[-0.18, -0.02, -0.13]}
          rotation={[-2.88, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip058"].geometry}
          material={materials.ID11}
          position={[-0.22, 0.01, -0.19]}
          rotation={[2.14, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip059"].geometry}
          material={materials.ID11}
          position={[-0.22, -0.04, -0.21]}
          rotation={[0.89, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip060"].geometry}
          material={materials.ID11}
          position={[-0.18, 0.02, -0.19]}
          rotation={[1.52, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip061"].geometry}
          material={materials.ID11}
          position={[-0.22, 0.01, -0.14]}
          rotation={[-2.88, 0.61, 0.53]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim032"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.04, -0.13]}
          rotation={[-1.5, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim033"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.04, -0.13]}
          rotation={[-2.13, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim034"].geometry}
          material={materials.ID51}
          position={[-0.19, 0.01, -0.19]}
          rotation={[1.64, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim035"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.01, -0.21]}
          rotation={[1.64, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim036"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.02, -0.13]}
          rotation={[-2.76, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim037"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.07, -0.17]}
          rotation={[-0.88, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim038"].geometry}
          material={materials.ID51}
          position={[-0.22, 0.02, -0.16]}
          rotation={[2.89, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim039"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.05, -0.2]}
          rotation={[-0.25, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim040"].geometry}
          material={materials.ID51}
          position={[-0.22, 0.01, -0.14]}
          rotation={[-2.76, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim041"].geometry}
          material={materials.ID51}
          position={[-0.22, 0.01, -0.19]}
          rotation={[2.27, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim042"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.03, -0.21]}
          rotation={[0.38, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim043"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.06, -0.2]}
          rotation={[0.38, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim044"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.06, -0.15]}
          rotation={[-0.88, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim045"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.03, -0.21]}
          rotation={[1.01, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim046"].geometry}
          material={materials.ID51}
          position={[-0.19, 0.01, -0.14]}
          rotation={[2.89, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim047"].geometry}
          material={materials.ID51}
          position={[-0.19, -0.06, -0.15]}
          rotation={[-1.5, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim048"].geometry}
          material={materials.ID51}
          position={[-0.19, 0, -0.21]}
          rotation={[1.01, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim049"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.07, -0.17]}
          rotation={[-0.25, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim050"].geometry}
          material={materials.ID51}
          position={[-0.22, -0.01, -0.13]}
          rotation={[-2.13, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim051"].geometry}
          material={materials.ID51}
          position={[-0.19, 0.02, -0.16]}
          rotation={[2.27, 0.66, 2.03]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003186"].geometry}
          material={materials.ID27}
          position={[0.21, -0.06, -0.16]}
          rotation={[-3.11, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003188"].geometry}
          material={materials.ID27}
          position={[0.21, 0, -0.2]}
          rotation={[-0.6, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003192"].geometry}
          material={materials.ID27}
          position={[0.19, -0.03, -0.13]}
          rotation={[-1.85, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003194"].geometry}
          material={materials.ID27}
          position={[0.21, -0.05, -0.2]}
          rotation={[-1.85, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003197"].geometry}
          material={materials.ID27}
          position={[0.21, 0, -0.13]}
          rotation={[1.29, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003204"].geometry}
          material={materials.ID27}
          position={[0.19, -0.06, -0.18]}
          rotation={[0.03, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003205"].geometry}
          material={materials.ID27}
          position={[0.19, -0.05, -0.13]}
          rotation={[-1.23, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003207"].geometry}
          material={materials.ID27}
          position={[0.21, 0.02, -0.15]}
          rotation={[0.66, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003208"].geometry}
          material={materials.ID27}
          position={[0.21, -0.03, -0.13]}
          rotation={[1.92, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003218"].geometry}
          material={materials.ID27}
          position={[0.19, -0.06, -0.16]}
          rotation={[-0.6, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003220"].geometry}
          material={materials.ID27}
          position={[0.19, 0, -0.13]}
          rotation={[-2.48, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003221"].geometry}
          material={materials.ID27}
          position={[0.21, -0.05, -0.14]}
          rotation={[2.54, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003223"].geometry}
          material={materials.ID27}
          position={[0.21, 0.02, -0.18]}
          rotation={[0.03, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003227"].geometry}
          material={materials.ID27}
          position={[0.19, 0.02, -0.15]}
          rotation={[-3.11, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003228"].geometry}
          material={materials.ID27}
          position={[0.21, -0.06, -0.18]}
          rotation={[-2.48, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003229"].geometry}
          material={materials.ID27}
          position={[0.19, -0.05, -0.2]}
          rotation={[0.66, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003233"].geometry}
          material={materials.ID27}
          position={[0.19, 0, -0.2]}
          rotation={[1.92, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003234"].geometry}
          material={materials.ID27}
          position={[0.19, 0.02, -0.18]}
          rotation={[2.54, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003236"].geometry}
          material={materials.ID27}
          position={[0.21, -0.02, -0.21]}
          rotation={[-1.23, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003244"].geometry}
          material={materials.ID27}
          position={[0.19, -0.02, -0.21]}
          rotation={[1.29, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003189"].geometry}
          material={materials.ID35}
          position={[0.19, 0, -0.2]}
          rotation={[1.92, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003191"].geometry}
          material={materials.ID35}
          position={[0.19, -0.05, -0.2]}
          rotation={[0.66, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003198"].geometry}
          material={materials.ID35}
          position={[0.19, -0.06, -0.18]}
          rotation={[0.03, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003206"].geometry}
          material={materials.ID35}
          position={[0.21, 0, -0.13]}
          rotation={[1.29, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003210"].geometry}
          material={materials.ID35}
          position={[0.21, 0.02, -0.18]}
          rotation={[0.03, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003211"].geometry}
          material={materials.ID35}
          position={[0.19, 0, -0.13]}
          rotation={[-2.48, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003212"].geometry}
          material={materials.ID35}
          position={[0.21, -0.03, -0.13]}
          rotation={[1.92, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003214"].geometry}
          material={materials.ID35}
          position={[0.19, 0.02, -0.15]}
          rotation={[-3.11, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003215"].geometry}
          material={materials.ID35}
          position={[0.19, -0.02, -0.21]}
          rotation={[1.29, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003216"].geometry}
          material={materials.ID35}
          position={[0.21, -0.05, -0.2]}
          rotation={[-1.85, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003217"].geometry}
          material={materials.ID35}
          position={[0.19, 0.02, -0.18]}
          rotation={[2.54, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003219"].geometry}
          material={materials.ID35}
          position={[0.21, -0.06, -0.16]}
          rotation={[-3.11, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003222"].geometry}
          material={materials.ID35}
          position={[0.21, 0, -0.2]}
          rotation={[-0.6, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003226"].geometry}
          material={materials.ID35}
          position={[0.21, 0.02, -0.15]}
          rotation={[0.66, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003230"].geometry}
          material={materials.ID35}
          position={[0.19, -0.03, -0.13]}
          rotation={[-1.85, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003232"].geometry}
          material={materials.ID35}
          position={[0.19, -0.06, -0.16]}
          rotation={[-0.6, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003237"].geometry}
          material={materials.ID35}
          position={[0.21, -0.05, -0.14]}
          rotation={[2.54, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003239"].geometry}
          material={materials.ID35}
          position={[0.21, -0.02, -0.21]}
          rotation={[-1.23, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003241"].geometry}
          material={materials.ID35}
          position={[0.21, -0.06, -0.18]}
          rotation={[-2.48, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003243"].geometry}
          material={materials.ID35}
          position={[0.19, -0.05, -0.13]}
          rotation={[-1.23, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003187"].geometry}
          material={materials.ID43}
          position={[0.19, -0.02, -0.21]}
          rotation={[1.29, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003190"].geometry}
          material={materials.ID43}
          position={[0.21, 0.02, -0.15]}
          rotation={[0.66, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003193"].geometry}
          material={materials.ID43}
          position={[0.21, -0.06, -0.16]}
          rotation={[-3.11, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003195"].geometry}
          material={materials.ID43}
          position={[0.19, 0, -0.13]}
          rotation={[-2.48, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003196"].geometry}
          material={materials.ID43}
          position={[0.21, 0.02, -0.18]}
          rotation={[0.03, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003199"].geometry}
          material={materials.ID43}
          position={[0.19, 0.02, -0.18]}
          rotation={[2.54, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003200"].geometry}
          material={materials.ID43}
          position={[0.19, -0.06, -0.18]}
          rotation={[0.03, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003201"].geometry}
          material={materials.ID43}
          position={[0.19, 0.02, -0.15]}
          rotation={[-3.11, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003202"].geometry}
          material={materials.ID43}
          position={[0.21, 0, -0.2]}
          rotation={[-0.6, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003203"].geometry}
          material={materials.ID43}
          position={[0.19, 0, -0.2]}
          rotation={[1.92, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003209"].geometry}
          material={materials.ID43}
          position={[0.19, -0.06, -0.16]}
          rotation={[-0.6, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003213"].geometry}
          material={materials.ID43}
          position={[0.21, -0.05, -0.14]}
          rotation={[2.54, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003224"].geometry}
          material={materials.ID43}
          position={[0.21, -0.05, -0.2]}
          rotation={[-1.85, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003225"].geometry}
          material={materials.ID43}
          position={[0.21, -0.03, -0.13]}
          rotation={[1.92, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003231"].geometry}
          material={materials.ID43}
          position={[0.21, 0, -0.13]}
          rotation={[1.29, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003235"].geometry}
          material={materials.ID43}
          position={[0.19, -0.05, -0.13]}
          rotation={[-1.23, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003238"].geometry}
          material={materials.ID43}
          position={[0.21, -0.06, -0.18]}
          rotation={[-2.48, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003240"].geometry}
          material={materials.ID43}
          position={[0.21, -0.02, -0.21]}
          rotation={[-1.23, -0.79, -1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003242"].geometry}
          material={materials.ID43}
          position={[0.19, -0.03, -0.13]}
          rotation={[-1.85, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1600-0407-0003245"].geometry}
          material={materials.ID43}
          position={[0.19, -0.05, -0.2]}
          rotation={[0.66, 0.79, 1.54]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207014"].geometry}
          material={materials.ID59}
          position={[0.21, 0, -0.18]}
          rotation={[1.92, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207015"].geometry}
          material={materials.ID59}
          position={[0.21, -0.04, -0.16]}
          rotation={[-1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207016"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, -0.15]}
          rotation={[-2.8, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0020_90128A207017"].geometry}
          material={materials.ID59}
          position={[0.21, -0.03, -0.19]}
          rotation={[0.35, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007014"].geometry}
          material={materials.ID59}
          position={[0.21, -0.03, -0.19]}
          rotation={[1.92, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007015"].geometry}
          material={materials.ID59}
          position={[0.21, 0, -0.18]}
          rotation={[-2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007016"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, -0.15]}
          rotation={[-1.22, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2812-0004-0007017"].geometry}
          material={materials.ID59}
          position={[0.21, -0.04, -0.16]}
          rotation={[0.35, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_right_slant_side_plate003"].geometry}
          material={materials.ID96}
          position={[0.2, -0.02, -0.17]}
          rotation={[1.92, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-0000-0096_right_slant_side_plate004"].geometry}
          material={materials.ID96}
          position={[0.21, -0.02, -0.17]}
          rotation={[-1.22, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_core005"].geometry}
          material={materials.ID19}
          position={[0.2, -0.02, -0.17]}
          rotation={[-2.8, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle032"].geometry}
          material={materials.ID74}
          position={[0.22, -0.05, -0.13]}
          rotation={[-1.22, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle033"].geometry}
          material={materials.ID74}
          position={[0.22, -0.06, -0.15]}
          rotation={[-0.6, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle034"].geometry}
          material={materials.ID74}
          position={[0.22, 0.02, -0.16]}
          rotation={[-3.11, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle035"].geometry}
          material={materials.ID74}
          position={[0.22, 0, -0.2]}
          rotation={[1.92, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle036"].geometry}
          material={materials.ID74}
          position={[0.22, 0.02, -0.18]}
          rotation={[2.55, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle037"].geometry}
          material={materials.ID74}
          position={[0.22, 0, -0.13]}
          rotation={[-2.48, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle038"].geometry}
          material={materials.ID74}
          position={[0.22, -0.07, -0.18]}
          rotation={[0.03, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle039"].geometry}
          material={materials.ID74}
          position={[0.22, -0.05, -0.2]}
          rotation={[0.66, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle040"].geometry}
          material={materials.ID74}
          position={[0.22, -0.03, -0.21]}
          rotation={[1.29, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_axle041"].geometry}
          material={materials.ID74}
          position={[0.22, -0.02, -0.12]}
          rotation={[-1.85, -0.68, 1.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core032"].geometry}
          material={materials.ID19}
          position={[0.19, -0.03, -0.13]}
          rotation={[-2.12, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core033"].geometry}
          material={materials.ID19}
          position={[0.19, 0, -0.13]}
          rotation={[-2.74, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core034"].geometry}
          material={materials.ID19}
          position={[0.19, -0.05, -0.2]}
          rotation={[0.4, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core035"].geometry}
          material={materials.ID19}
          position={[0.19, 0.02, -0.18]}
          rotation={[2.28, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core036"].geometry}
          material={materials.ID19}
          position={[0.19, -0.02, -0.21]}
          rotation={[1.03, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core037"].geometry}
          material={materials.ID19}
          position={[0.19, -0.05, -0.14]}
          rotation={[-1.49, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core038"].geometry}
          material={materials.ID19}
          position={[0.19, -0.06, -0.18]}
          rotation={[-0.23, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core039"].geometry}
          material={materials.ID19}
          position={[0.19, -0.07, -0.16]}
          rotation={[-0.86, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core040"].geometry}
          material={materials.ID19}
          position={[0.19, 0, -0.2]}
          rotation={[1.65, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_core041"].geometry}
          material={materials.ID19}
          position={[0.19, 0.02, -0.15]}
          rotation={[2.91, -0.77, 1.39]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover032"].geometry}
          material={materials.ID19}
          position={[0.18, 0.02, -0.24]}
          rotation={[-3.02, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover033"].geometry}
          material={materials.ID19}
          position={[0.18, -0.03, -0.26]}
          rotation={[2.63, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover034"].geometry}
          material={materials.ID19}
          position={[0.18, -0.11, -0.19]}
          rotation={[1.38, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover035"].geometry}
          material={materials.ID19}
          position={[0.18, -0.08, -0.24]}
          rotation={[2.01, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover036"].geometry}
          material={materials.ID19}
          position={[0.18, 0.06, -0.2]}
          rotation={[-2.39, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover037"].geometry}
          material={materials.ID19}
          position={[0.18, -0.02, -0.08]}
          rotation={[-0.51, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover038"].geometry}
          material={materials.ID19}
          position={[0.18, -0.11, -0.14]}
          rotation={[0.75, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover039"].geometry}
          material={materials.ID19}
          position={[0.18, -0.07, -0.09]}
          rotation={[0.12, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover040"].geometry}
          material={materials.ID19}
          position={[0.18, 0.06, -0.15]}
          rotation={[-1.76, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_cover041"].geometry}
          material={materials.ID19}
          position={[0.18, 0.03, -0.1]}
          rotation={[-1.14, 0.39, 1.75]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip062"].geometry}
          material={materials.ID11}
          position={[0.18, -0.03, -0.13]}
          rotation={[-1.95, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip063"].geometry}
          material={materials.ID11}
          position={[0.22, -0.07, -0.18]}
          rotation={[0.03, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip064"].geometry}
          material={materials.ID11}
          position={[0.22, 0.02, -0.16]}
          rotation={[-3.11, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip065"].geometry}
          material={materials.ID11}
          position={[0.18, 0, -0.13]}
          rotation={[-2.57, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip066"].geometry}
          material={materials.ID11}
          position={[0.22, 0.02, -0.18]}
          rotation={[2.55, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip067"].geometry}
          material={materials.ID11}
          position={[0.22, -0.02, -0.12]}
          rotation={[-1.85, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip068"].geometry}
          material={materials.ID11}
          position={[0.18, -0.05, -0.21]}
          rotation={[0.57, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip069"].geometry}
          material={materials.ID11}
          position={[0.18, 0.02, -0.18]}
          rotation={[2.45, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip070"].geometry}
          material={materials.ID11}
          position={[0.18, 0.02, -0.15]}
          rotation={[3.08, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip071"].geometry}
          material={materials.ID11}
          position={[0.22, -0.05, -0.13]}
          rotation={[-1.22, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip072"].geometry}
          material={materials.ID11}
          position={[0.22, 0, -0.13]}
          rotation={[-2.48, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip073"].geometry}
          material={materials.ID11}
          position={[0.22, -0.06, -0.15]}
          rotation={[-0.6, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip074"].geometry}
          material={materials.ID11}
          position={[0.18, -0.02, -0.21]}
          rotation={[1.2, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip075"].geometry}
          material={materials.ID11}
          position={[0.22, -0.05, -0.2]}
          rotation={[0.66, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip076"].geometry}
          material={materials.ID11}
          position={[0.18, 0, -0.2]}
          rotation={[1.82, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip077"].geometry}
          material={materials.ID11}
          position={[0.18, -0.05, -0.14]}
          rotation={[-1.32, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip078"].geometry}
          material={materials.ID11}
          position={[0.18, -0.06, -0.19]}
          rotation={[-0.06, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip079"].geometry}
          material={materials.ID11}
          position={[0.18, -0.07, -0.16]}
          rotation={[-0.69, -0.78, -0.06]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip080"].geometry}
          material={materials.ID11}
          position={[0.22, -0.03, -0.21]}
          rotation={[1.29, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_e-clip081"].geometry}
          material={materials.ID11}
          position={[0.22, 0, -0.2]}
          rotation={[1.92, -0.68, 2.71]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim052"].geometry}
          material={materials.ID51}
          position={[0.19, -0.05, -0.2]}
          rotation={[0.72, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim053"].geometry}
          material={materials.ID51}
          position={[0.19, 0.02, -0.15]}
          rotation={[-3.05, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim054"].geometry}
          material={materials.ID51}
          position={[0.19, -0.06, -0.18]}
          rotation={[0.1, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim055"].geometry}
          material={materials.ID51}
          position={[0.19, -0.02, -0.21]}
          rotation={[1.35, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim056"].geometry}
          material={materials.ID51}
          position={[0.19, -0.03, -0.13]}
          rotation={[-1.79, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim057"].geometry}
          material={materials.ID51}
          position={[0.19, 0.02, -0.18]}
          rotation={[2.61, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim058"].geometry}
          material={materials.ID51}
          position={[0.19, 0, -0.2]}
          rotation={[1.98, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim059"].geometry}
          material={materials.ID51}
          position={[0.19, 0, -0.13]}
          rotation={[-2.42, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim060"].geometry}
          material={materials.ID51}
          position={[0.19, -0.07, -0.16]}
          rotation={[-0.53, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["3606-XXXX-0096_roller_shim061"].geometry}
          material={materials.ID51}
          position={[0.19, -0.05, -0.14]}
          rotation={[-1.16, -0.78, 1.62]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2110-0608-0001009"].geometry}
          material={materials.ID201}
          position={[0.2, -0.02, 0.17]}
          rotation={[-2.94, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0010009"].geometry}
          material={materials.ID59}
          position={[0.14, -0.02, 0.17]}
          rotation={[-2.94, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0011009"].geometry}
          material={materials.ID59}
          position={[0.13, -0.02, 0.17]}
          rotation={[-1.37, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id159010.geometry}
          material={materials.ID193}
          position={[0.18, -0.02, 0.17]}
          rotation={[-1.37, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id164010.geometry}
          material={materials.ID193}
          position={[0.18, -0.02, 0.17]}
          rotation={[-1.37, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id169010.geometry}
          material={materials.ID193}
          position={[0.18, -0.02, 0.17]}
          rotation={[-1.37, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id179010.geometry}
          material={materials.ID237}
          position={[0.14, -0.02, 0.17]}
          rotation={[1.77, 0, Math.PI / 2]}
        />
        <group position={[0.14, -0.02, 0.17]} rotation={[1.77, 0, Math.PI / 2]}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177.geometry}
            material={materials.ID149}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_1.geometry}
            material={materials.ID183}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_2.geometry}
            material={materials.ID184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_3.geometry}
            material={materials.ID185}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id189010.geometry}
          material={materials.ID11}
          position={[0.14, -0.02, 0.17]}
          rotation={[1.77, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030013"].geometry}
          material={materials.ID173}
          position={[0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, -0.2, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004025"].geometry}
          material={materials.ID165}
          position={[0.16, -0.03, 0.16]}
          rotation={[Math.PI / 2, 0.72, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004026"].geometry}
          material={materials.ID165}
          position={[0.15, -0.03, 0.16]}
          rotation={[Math.PI / 2, -1.37, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030016"].geometry}
          material={materials.ID173}
          position={[0.18, -0.02, 0.17]}
          rotation={[-2.94, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004031"].geometry}
          material={materials.ID165}
          position={[0.17, -0.03, 0.17]}
          rotation={[1.77, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004032"].geometry}
          material={materials.ID165}
          position={[0.17, -0.02, 0.17]}
          rotation={[-0.32, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030014"].geometry}
          material={materials.ID173}
          position={[0.18, -0.02, -0.17]}
          rotation={[-2.8, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004027"].geometry}
          material={materials.ID165}
          position={[0.17, -0.03, -0.17]}
          rotation={[1.92, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004028"].geometry}
          material={materials.ID165}
          position={[0.17, -0.02, -0.16]}
          rotation={[-0.18, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2315-4008-0030015"].geometry}
          material={materials.ID173}
          position={[0.16, -0.02, -0.14]}
          rotation={[Math.PI / 2, -0.35, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004029"].geometry}
          material={materials.ID165}
          position={[0.16, -0.02, -0.16]}
          rotation={[-Math.PI / 2, 0.87, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2806-0005-0004030"].geometry}
          material={materials.ID165}
          position={[0.15, -0.02, -0.16]}
          rotation={[-Math.PI / 2, -1.22, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins021"].geometry}
          material={materials.ID149}
          position={[0.15, -0.02, 0.04]}
          rotation={[-Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9021.geometry}
          material={materials.ID149}
          position={[0.15, -0.02, 0.04]}
          rotation={[-Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins022"].geometry}
          material={materials.ID149}
          position={[0.15, -0.01, 0.04]}
          rotation={[-Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9022.geometry}
          material={materials.ID149}
          position={[0.15, -0.01, 0.04]}
          rotation={[-Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing029"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34029.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing030"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34030.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Barrel_(2_stage,_simple)011"].geometry}
          material={materials.ID165}
          position={[0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)081"]
              .geometry
          }
          material={materials.ID149}
          position={[0.14, -0.02, 0.12]}
          rotation={[-1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)082"]
              .geometry
          }
          material={materials.ID149}
          position={[0.14, -0.02, 0.1]}
          rotation={[-1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)083"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.04, 0.1]}
          rotation={[3.14, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)084"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.01, 0.12]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)085"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.04, 0.12]}
          rotation={[0, -1.57, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)086"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.01, 0.1]}
          rotation={[-Math.PI, 1.57, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)087"]
              .geometry
          }
          material={materials.ID149}
          position={[0.17, -0.02, 0.1]}
          rotation={[1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)088"]
              .geometry
          }
          material={materials.ID149}
          position={[0.17, -0.02, 0.12]}
          rotation={[1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Base011"].geometry}
          material={materials.ID396}
          position={[0.16, -0.02, 0.1]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Face011"].geometry}
          material={materials.ID396}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Snap_Ring011"].geometry}
          material={materials.ID165}
          position={[0.16, -0.02, 0.14]}
          rotation={[-Math.PI, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw013"].geometry}
          material={materials.ID59}
          position={[0.15, -0.04, 0.03]}
          rotation={[-Math.PI / 2, 0.49, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw014"].geometry}
          material={materials.ID59}
          position={[0.16, -0.01, 0.03]}
          rotation={[-Math.PI / 2, 0.49, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap007"].geometry}
          material={materials.ID19}
          position={[0.16, -0.02, 0.02]}
          rotation={[-Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pin_housing007"].geometry}
          material={materials.ID298}
          position={[0.15, -0.01, 0.04]}
          rotation={[0, 0, Math.PI / 4]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_motor_shield007"].geometry}
          material={materials.ID359}
          position={[0.16, -0.02, 0.09]}
          rotation={[-Math.PI / 2, -0.63, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0001_body007"].geometry}
          material={materials.ID74}
          position={[0.16, -0.02, 0.04]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0117_motor_shaft007"].geometry}
          material={materials.ID351}
          position={[0.16, -0.02, 0.1]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)025"]
              .geometry
          }
          material={materials.ID306}
          position={[0.16, -0.02, 0.1]}
          rotation={[1.57, -1.51, 3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)026"]
              .geometry
          }
          material={materials.ID306}
          position={[0.15, -0.03, 0.1]}
          rotation={[-1.57, 1.51, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)027"]
              .geometry
          }
          material={materials.ID306}
          position={[0.15, -0.02, 0.1]}
          rotation={[Math.PI / 2, 0.06, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)028"]
              .geometry
          }
          material={materials.ID306}
          position={[0.16, -0.03, 0.1]}
          rotation={[-Math.PI / 2, -0.06, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Shaft_(235mm_Length,_Simple)007"].geometry
          }
          material={materials.ID149}
          position={[0.16, -0.02, 0.12]}
          rotation={[Math.PI, 0, 1.37]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins024"].geometry}
          material={materials.ID149}
          position={[0.15, -0.03, -0.04]}
          rotation={[Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9024.geometry}
          material={materials.ID149}
          position={[0.15, -0.03, -0.04]}
          rotation={[Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pins023"].geometry}
          material={materials.ID149}
          position={[0.15, -0.04, -0.04]}
          rotation={[Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_9023.geometry}
          material={materials.ID149}
          position={[0.15, -0.04, -0.04]}
          rotation={[Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing031"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34031.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Bearing032"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_17032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_18032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_19032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_20032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_21032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_22032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_23032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_24032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_25032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_26032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_27032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_28032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_29032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_30032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_31032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_32032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_33032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Part_34032.geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Barrel_(2_stage,_simple)012"].geometry}
          material={materials.ID165}
          position={[0.16, -0.02, -0.14]}
          rotation={[Math.PI / 2, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)089"]
              .geometry
          }
          material={materials.ID149}
          position={[0.14, -0.02, -0.12]}
          rotation={[1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)090"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.01, -0.12]}
          rotation={[Math.PI, -1.57, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)091"]
              .geometry
          }
          material={materials.ID149}
          position={[0.14, -0.02, -0.1]}
          rotation={[1.57, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)092"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.01, -0.1]}
          rotation={[0, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)093"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.04, -0.1]}
          rotation={[0, 1.57, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)094"]
              .geometry
          }
          material={materials.ID149}
          position={[0.16, -0.04, -0.12]}
          rotation={[-3.14, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)095"]
              .geometry
          }
          material={materials.ID149}
          position={[0.17, -0.02, -0.1]}
          rotation={[-1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Barrel_Screw_(M3_x_8mm_length_Flat_Head)096"]
              .geometry
          }
          material={materials.ID149}
          position={[0.17, -0.02, -0.12]}
          rotation={[-1.57, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Base012"].geometry}
          material={materials.ID396}
          position={[0.16, -0.02, -0.1]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Face012"].geometry}
          material={materials.ID396}
          position={[0.16, -0.02, -0.12]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5103_Gearbox_Snap_Ring012"].geometry}
          material={materials.ID165}
          position={[0.16, -0.02, -0.14]}
          rotation={[0, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw015"].geometry}
          material={materials.ID59}
          position={[0.16, -0.04, -0.03]}
          rotation={[Math.PI / 2, 0.49, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap_screw016"].geometry}
          material={materials.ID59}
          position={[0.15, -0.01, -0.03]}
          rotation={[Math.PI / 2, 0.49, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_cap008"].geometry}
          material={materials.ID19}
          position={[0.16, -0.02, -0.02]}
          rotation={[Math.PI / 2, -Math.PI / 4, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_encoder_pin_housing008"].geometry}
          material={materials.ID298}
          position={[0.15, -0.03, -0.04]}
          rotation={[Math.PI, 0, Math.PI / 4]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0001-0001_motor_shield008"].geometry}
          material={materials.ID359}
          position={[0.16, -0.02, -0.09]}
          rotation={[Math.PI / 2, -0.63, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0001_body008"].geometry}
          material={materials.ID74}
          position={[0.16, -0.02, -0.04]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["5000-0002-0117_motor_shaft008"].geometry}
          material={materials.ID351}
          position={[0.16, -0.02, -0.1]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)029"]
              .geometry
          }
          material={materials.ID306}
          position={[0.15, -0.03, -0.1]}
          rotation={[-Math.PI / 2, 0.06, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)030"]
              .geometry
          }
          material={materials.ID306}
          position={[0.16, -0.03, -0.1]}
          rotation={[-1.57, -1.51, -3.14]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)031"]
              .geometry
          }
          material={materials.ID306}
          position={[0.16, -0.02, -0.1]}
          rotation={[Math.PI / 2, -0.06, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Base_Mounting_Screw_M3_x_5mm_length_SHCS)032"]
              .geometry
          }
          material={materials.ID306}
          position={[0.15, -0.02, -0.1]}
          rotation={[1.57, 1.51, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={
            nodes["5103_Gearbox_Shaft_(235mm_Length,_Simple)008"].geometry
          }
          material={materials.ID149}
          position={[0.16, -0.02, -0.12]}
          rotation={[0, 0, 1.22]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2110-0608-0001010"].geometry}
          material={materials.ID201}
          position={[0.2, -0.02, -0.17]}
          rotation={[-2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0010010"].geometry}
          material={materials.ID59}
          position={[0.14, -0.02, -0.17]}
          rotation={[-2.8, Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0011010"].geometry}
          material={materials.ID59}
          position={[0.13, -0.02, -0.17]}
          rotation={[-1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id159011.geometry}
          material={materials.ID193}
          position={[0.18, -0.02, -0.17]}
          rotation={[-1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id164011.geometry}
          material={materials.ID193}
          position={[0.18, -0.02, -0.17]}
          rotation={[-1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id169011.geometry}
          material={materials.ID193}
          position={[0.18, -0.02, -0.17]}
          rotation={[-1.22, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id179011.geometry}
          material={materials.ID237}
          position={[0.14, -0.02, -0.17]}
          rotation={[1.92, 0, Math.PI / 2]}
        />
        <group
          position={[0.14, -0.02, -0.17]}
          rotation={[1.92, 0, Math.PI / 2]}
        >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177.geometry}
            material={materials.ID149}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_1.geometry}
            material={materials.ID183}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_2.geometry}
            material={materials.ID184}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.ID177_3.geometry}
            material={materials.ID185}
          />
        </group>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.id189011.geometry}
          material={materials.ID11}
          position={[0.14, -0.02, -0.17]}
          rotation={[1.92, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002009"].geometry}
          material={materials.ID119}
          position={[0.16, -0.02, 0.14]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1201-0043-0002010"].geometry}
          material={materials.ID119}
          position={[0.16, -0.02, -0.14]}
          rotation={[Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011049"].geometry}
          material={materials.ID59}
          position={[0.2, -0.01, 0.16]}
          rotation={[-2.94, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011050"].geometry}
          material={materials.ID59}
          position={[0.16, -0.03, 0.13]}
          rotation={[-Math.PI, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011051"].geometry}
          material={materials.ID59}
          position={[0.2, -0.03, -0.18]}
          rotation={[-2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011052"].geometry}
          material={materials.ID59}
          position={[0.16, -0.03, -0.13]}
          rotation={[0, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011053"].geometry}
          material={materials.ID59}
          position={[0.15, -0.03, -0.13]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011054"].geometry}
          material={materials.ID59}
          position={[0.14, -0.01, -0.14]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011055"].geometry}
          material={materials.ID59}
          position={[0.15, -0.02, 0.13]}
          rotation={[Math.PI, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011056"].geometry}
          material={materials.ID59}
          position={[0.2, -0.02, -0.16]}
          rotation={[-2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011057"].geometry}
          material={materials.ID59}
          position={[0.14, -0.04, 0.14]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011058"].geometry}
          material={materials.ID59}
          position={[0.2, -0.02, 0.18]}
          rotation={[-2.94, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011059"].geometry}
          material={materials.ID59}
          position={[0.16, -0.02, -0.13]}
          rotation={[0, 0, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011060"].geometry}
          material={materials.ID59}
          position={[0.14, -0.04, -0.14]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011061"].geometry}
          material={materials.ID59}
          position={[0.15, -0.02, -0.13]}
          rotation={[0, 0, -Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011062"].geometry}
          material={materials.ID59}
          position={[0.17, -0.01, 0.14]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011063"].geometry}
          material={materials.ID59}
          position={[0.2, -0.03, -0.16]}
          rotation={[-2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011064"].geometry}
          material={materials.ID59}
          position={[0.15, -0.03, 0.13]}
          rotation={[-Math.PI, 0, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011065"].geometry}
          material={materials.ID59}
          position={[0.2, -0.01, -0.17]}
          rotation={[-2.8, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011066"].geometry}
          material={materials.ID59}
          position={[0.17, -0.04, -0.14]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011067"].geometry}
          material={materials.ID59}
          position={[0.14, -0.01, 0.14]}
          rotation={[-Math.PI / 2, 1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011068"].geometry}
          material={materials.ID59}
          position={[0.17, -0.04, 0.14]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011069"].geometry}
          material={materials.ID59}
          position={[0.16, -0.02, 0.13]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011070"].geometry}
          material={materials.ID59}
          position={[0.2, -0.03, 0.16]}
          rotation={[-2.94, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011071"].geometry}
          material={materials.ID59}
          position={[0.17, -0.01, -0.14]}
          rotation={[-Math.PI / 2, -1.57, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2800-0004-0011072"].geometry}
          material={materials.ID59}
          position={[0.2, -0.03, 0.17]}
          rotation={[-2.94, -Math.PI / 2, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1120-0017-0432002"].geometry}
          material={materials.ID119}
          position={[0.16, 0, 0.22]}
          rotation={[Math.PI, 0, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1522-0010-0120003"].geometry}
          material={materials.ID119}
          position={[0.16, -0.02, 0.15]}
          rotation={[0, 0, 1.77]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["1522-0010-0120004"].geometry}
          material={materials.ID119}
          position={[0.16, -0.02, -0.15]}
          rotation={[-Math.PI, 0, 1.92]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008009"].geometry}
          material={materials.ID59}
          position={[0.21, -0.02, -0.16]}
          rotation={[0.35, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008010"].geometry}
          material={materials.ID59}
          position={[0.21, -0.03, -0.18]}
          rotation={[0.35, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008011"].geometry}
          material={materials.ID59}
          position={[0.21, -0.03, 0.17]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008012"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, -0.17]}
          rotation={[0.35, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008013"].geometry}
          material={materials.ID59}
          position={[0.21, -0.03, -0.16]}
          rotation={[0.35, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008014"].geometry}
          material={materials.ID59}
          position={[0.21, -0.01, 0.16]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008015"].geometry}
          material={materials.ID59}
          position={[0.21, -0.03, 0.16]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2801-0004-0008016"].geometry}
          material={materials.ID59}
          position={[0.21, -0.02, 0.18]}
          rotation={[0.2, 0, Math.PI / 2]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500007"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.14]}
          rotation={[-Math.PI / 2, -1.22, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500008"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.14]}
          rotation={[1.57, -1.37, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500009"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.14]}
          rotation={[1.57, -1.37, Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500010"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.14]}
          rotation={[-Math.PI / 2, -1.22, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500011"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, -0.14]}
          rotation={[-Math.PI / 2, -1.22, -Math.PI]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes["2807-0811-0500012"].geometry}
          material={materials.ID149}
          position={[0.16, -0.02, 0.14]}
          rotation={[1.57, -1.37, Math.PI]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("./models/Robot.gltf");